/* eslint-disable vue/valid-v-bind */
<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="participants"
      sort-by="name"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Оролцогчид ({{ participants.length }}) </v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          />
          <v-spacer />
          <small>{{ new Date | moment("dddd, MMMM Do YYYY, h:mm:ss a") }}</small>
        </v-toolbar> 
      </template>
      <template
        slot="item.idd"
        scope="props"
      >
        {{ props.index + 1 }}
      </template>
      <template v-slot:[`item.endedAt`]="{ item }">
        {{ item | moment("h:mm:ss a") }}
      </template>
      <template v-slot:[`item.allScored`]="{ item }">
        <v-chip :color="_getColor(item.allScored)">
          {{ item.allScored }} 
        </v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click.stop="_viewUser(item)"
        >
          mdi-eye
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn @click="_initialize">
          Шинэчлэx
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialog"
      max-width="40%"
      scrollable
    >
      <v-card>
        <v-card-title
          v-if="currentSelectedUser"
          class="headline"
        >
          {{ currentSelectedUser.name }}
        </v-card-title>
        <v-card-text>
          <z-result
            :asuulga-id="VFMYBMmCkNJ9dBZsuN8Q"
            :user-id="GaAo2AT6zujp2oPjJZEb"
            :result-id="yhUOZxd7fSwPMUODtndU"
          />
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            class="white--text px-10  mb-10"
            style="min-width: 0;"
            color="secondary lighten-1"
            @click="_openCrtWindow"
          >
            Гэрчилгээ аваx
          </v-btn>
          <!-- <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            OK
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<style>
.v-ripple__container {
  display: none !important;
}
</style>
<script>

  import ZResult from './ZResult'
  const fb = require('../../firebaseConfig.js')

  export default {
    filters: {
      capitalize: function (value) {
        if (!value) return ''
        value = value.toString()
        // return value.charAt(0).toUpperCase() + value.slice(1)
        return value.toUpperCase()
      },
    },
    components: {
      ZResult,
    },
    props: {
      asuulgaId: {
        type: String,
        required: false,
      },
    },
    data: () => ({
      color: '#ccc',
      participants: [],
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'ID', value: 'idd' },
        {
          text: 'Нэр',
          align: 'start',
          sortable: false,
          value: 'name',
          class: "secondary--text",
        },
        {
          text: 'Овог',
          align: 'start',
          sortable: false,
          value: 'lastname',
          class: "secondary--text",
        },
        { text: 'Регистр', value: 'register', class: "secondary--text" },
        { text: 'Утасны дугаар', value: 'phone', class: "secondary--text" },
        { text: 'Аваx оноо', value: 'allScore', class: "secondary--text" },
        { text: 'Авсан оноо', value: 'allScored', class: "secondary--text" },
        { text: 'Дууссан', value: 'endedAt', class: "secondary--text font-weight-regular" },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        name: '',
        lastname: '',
        register: '',
        phone: '',
      },
      defaultItem: {
        name: '',
        lastname: '',
        register: '',
        phone: '',
      },
      currentSelectedUser: null,

    }),
    watch: {
      questionType: function (val) {
        console.log(val)
        if (val === 0) this.question.multichoice = false
        else this.question.multichoice = true

        this._updateQuestion()
      },
    },
    created() {
      this.participants = []
      this._initialize()
    },
    methods: {
      _viewUser(item){
        this.currentSelectedUser = item
        console.log(this.currentSelectedUser)
        this.dialog = true
      },
      _getColor(score) {
        if (score < 1) return 'red'
        else if (score > 5) return 'orange'
        else return 'transparent'
      },
      _openCrtWindow() {
        const routeData = this.$router.resolve({ name: 'ViewCertification' })
        window.open(routeData.href, '_blank')
        this.dialog = false
      },
      async _initialize() {
        this.participants = []
        fb.db
          .collection('asuulga/' + this.asuulgaId + '/users')
          .orderBy('createdAt', 'asc')
          .onSnapshot((querySnapshot) => {
            querySnapshot.forEach(async (userDoc) => {
              const user = userDoc.data()
              user.id = userDoc.id
              user.ref = userDoc.ref
              console.log(user)

              var datas = await userDoc.ref.collection('answered').orderBy('createdAt', 'asc').limit(1).get()
              if (!datas.empty) {
                var userObj = datas.docs[0]
                if (userObj != null && userObj.exists) {
                  user.allScore = userObj.data().allScore
                  user.allScored = userObj.data().allScored
                  user.endedAt = new Date(userObj.data().createdAt.toDate())
                  user.userData = userObj.data()
                }
              }
              this.participants.push(user)
            })
          })
      },
    },
  }
</script>
