<template>
  <v-contaner>
    <v-data-table
      :headers="headers"
      :items="endQuestions"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="secondary--text; font-weight-bold">
            Үр дүн
          </v-toolbar-title>
          <v-spacer />
          <v-switch
            v-model="singleExpand"
            label="Нийтээр нь дэлгэx"
            class="mt-2"
          />
        </v-toolbar>
      </template>
      <template
        slot="item.idd"
        scope="props"
      >
        {{ props.index + 1 }}
      </template>
      <template
        v-slot:
        item.scored="{ item }"
      >
        <v-chip
          :color="getColor(item.scored)"
          dark
        > 
          {{ item.scored }}
        </v-chip>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          More info about {{ item.name }}
        </td>
      </template>
    </v-data-table>
    <div class="text-center mt-10">
      <!-- <iframe id="pdfV" style="width: 100%; height: 500px"> </iframe> -->
      <v-alert
        text
        type="error"
        icon="mdi-cloud-alert"
        border="left"
      >
        Оролцогчийн сертификатыг доорx товч дээр дарсанаар аваx боломжтой.
      </v-alert>
    </div>
  </v-contaner>
</template>
<style>
.v-ripple__container {
  display: none !important;
}
</style>
<script>
  const fb = require("../../firebaseConfig.js")
  export default {
    name: "ZResult",
    filters: {
      capitalize: function (value) {
        if (!value) return ""
        value = value.toString()
        // return value.charAt(0).toUpperCase() + value.slice(1)
        return value.toUpperCase()
      },
    },
    // asuulga/VFMYBMmCkNJ9dBZsuN8Q/users/GaAo2AT6zujp2oPjJZEb/answered/yhUOZxd7fSwPMUODtndU
    props: {
      asuulgaId: {
        type: String,
        required: false,
      },
      userId: {
        type: String,
        required: false,
      },
      resultId: {
        type: String,
        required: false,
      },
    },
    data: () => ({
      color: "#ccc",
      categories: [],
      singleExpand: false,
      headers: [
        { text: 'ID', value: 'idd' },
        {
          text: "Асуулт",
          align: "start",
          sortable: false,
          value: "name",
          class: "secondary--text"
        },
        { text: "Аваx оноо", value: "score", class: "secondary--text"},
        { text: "Авсан оноо", value: "scored", class: "secondary--text" },
        { text: "", value: "data-table-expand", class: "secondary--text"},
      ],
    }),
    computed: {
      endQuestions() {
        var questions = []
        for (var cat of this.categories) {
          console.log(cat, cat.questions.length)
          for (var question of cat.questions) {
            questions.push({
              index: 1,
              name: question.name,
              score: question.score,
              scored: this._calScore(question),
            })
          }
        }
        return questions
      // return [{name:'xxaa'}, {name:'agasdgVVVVV'}];
      },
    },
    watch: {},
    created() {
      // this._initialize();
      this.asuulgaId = "VFMYBMmCkNJ9dBZsuN8Q"
      this.userId = "GaAo2AT6zujp2oPjJZEb"
      this.resultId = "yhUOZxd7fSwPMUODtndU"
      this._findCategories()
    },
    methods: {
      _calScore(question) {
        var scored = 0
        console.log(question.id)
        for (var answer of question.answers) {
          if (answer.correct) console.log(answer)
          // if(answer.scored!=null&&answer.scored!=undefined)
          if (answer.answered && answer.correct) scored = scored + answer.scored
        }
        return scored
      },
      getColor(score) {
        if (score < 1) return "red"
        else if (score > 5) return "orange"
        else return "green"
      },
      _getAnswers(question) {
        console.log(question.ref.path)
        question.ref
          .collection("answers")
          .orderBy("createdAt", "asc")
          .onSnapshot((querySnapshot) => {
            question.answers = []
            querySnapshot.forEach((doc) => {
              const answer = doc.data()
              answer.id = doc.id
              console.log(answer)
              question.answers.push(answer)
              console.log(
                answer.id,
                answer.answered,
                answer.correct,
                question.score,
                answer.scored,
              )
            })
          })
      },
      _getQuestionsOfCategory(cat) {
        cat.ref
          .collection("/questions")
          .orderBy("createdAt", "asc")
          .onSnapshot((querySnapshot) => {
            cat.questions = []
            querySnapshot.forEach((doc) => {
              const question = doc.data()
              question.id = doc.id
              question.ref = doc.ref
              question.category = cat.name
              question.statData = null
              question.datacollection = null
              console.log(question)
              this._getAnswers(question)
              cat.questions.push(question)
            })
          })
      },

      async _findCategories() {
        var asuulgaDoc = await fb.db
          .collection("asuulga")
          .doc(this.asuulgaId)
          .get()
        if (asuulgaDoc.exists) {
          this.asuulga = asuulgaDoc.data()
          this.asuulga.id = asuulgaDoc.id
          this.asuulga.ref = asuulgaDoc.ref
        }
        //  /asuulga/VFMYBMmCkNJ9dBZsuN8Q/users/L37yqtD1oaBKqZEbKSiZ/answered/8BsD32MTp1nmTjo2y4t9/categories/WyGjiVSOlAIwmu7k15Wj
        console.log(
          "asuulga/" +
            this.asuulgaId +
            "/users/" +
            this.userId +
            "/answered/" +
            this.resultId,
        )
        fb.db
          .collection("asuulga/" + this.asuulgaId + "/users")
          .doc(this.userId)
          .collection("answered")
          .doc(this.resultId)
          .collection("categories")
          // .orderBy("createdAt", "asc")
          .onSnapshot((querySnapshot) => {
            this.categories = []
            querySnapshot.forEach((catDoc) => {
              const cat = catDoc.data()
              cat.id = catDoc.id
              cat.ref = catDoc.ref
              cat.questions = [] 
              console.log(cat.name)
              this._getQuestionsOfCategory(cat)
              this.categories.push(cat)
            })
          })
      },
      _initialize() {
        fb.db
          .collection("asuulga/" + this.asuulgaId + "/users")
          .orderBy("createdAt", "asc")
          .onSnapshot((querySnapshot) => {
            querySnapshot.forEach((userDoc) => {
              const user = userDoc.data()
              user.id = userDoc.id
              user.ref = userDoc.ref
              console.log(user)
            })
          })
      },
    },
  }
</script>
